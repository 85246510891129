// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthenticationPage from './pages/AuthenticationPage/AuthenticationPage';
import AuthRedirectPage from './pages/AuthRedirectPage/AuthRedirectPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import IntroPage from './pages/IntroPage/IntroPage';
import AboutPage from './pages/AboutPage/AboutPage';
import CareerPage from './pages/CareerPage/CareerPage';
import ToSAndPP from './pages/ToSAndPP/ToSAndPP';
import { AuthGuard } from './utils/authGuard';
import BackgroundVideo from './components/BackgroundVideo/BackgroundVideo';
import CookiesAgreement from './components/CookiesAgreement/CookiesAgreement';
import './App.css';

function App() {
    const backgroundVideoBlacklist = ["terms", "privacy"];
    return (
        <div className="App">
            { backgroundVideoBlacklist.includes(window.location.pathname.split("/")[1]) ? null : 
                <BackgroundVideo video="https://realmz-assets.s3.us-east-2.amazonaws.com/background-video-1-slowmotion-smooth.mp4" />
            }
            <Router>
                <Routes>
                    <Route exact path="/" element={ <IntroPage /> } />
                    <Route path="/about" element={ <AboutPage /> } />
                    <Route path="/career" element={ <CareerPage /> } />
                    <Route path="/auth" element={ <AuthGuard><AuthenticationPage /></AuthGuard> } />
                    <Route path="/auth/redirect" element={ <AuthRedirectPage /> } />
                    <Route path="/terms" element={ <ToSAndPP type="tos" /> } />
                    <Route path="/privacy" element={ <ToSAndPP type="privacy" /> } />
                    <Route path="*" element={ <NotFoundPage />} />
                </Routes>
            </Router>
            <CookiesAgreement />
        </div>
    );
}

export default App;