import "./BackgroundVideo.css";

import React from "react";

const BackgroundVideo = ({ video }) => {
  return (
    <div className="background-video">
      <video autoPlay loop muted playsInline className="background-video-container">
        <source
          src={video}
          type="video/mp4"
        />
      </video>
      <div className="background-video-mask" />
    </div>
  );
}

export default BackgroundVideo;
