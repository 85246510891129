import "./ToSAndPP.css";
import React, { useEffect, useRef } from 'react';

const ToSAndPP = ({ type="tos" }) => {
  const embedRef = useRef(null); // Create a ref for the embed div

  useEffect(() => {
    // Function to dynamically load the GetTerms script
    const loadScript = () => {
      const scriptId = 'getterms-embed-js';
      if (document.getElementById(scriptId)) {
        return; // Exit if script already exists
      }

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = "https://app.getterms.io/dist/js/embed.js";
      script.async = true;
      script.onload = () => {
        // Optional: Any additional actions after the script loads
      };

      document.body.appendChild(script);

      return () => {
        // Cleanup function to remove script when component unmounts
        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      };
    };

    if (embedRef.current) {
      loadScript();
    }
  }, [embedRef]); // Depend on embedRef to ensure script loads after the div is mounted

  return (
    <div ref={embedRef} className="getterms-document-embed" 
         data-getterms="HIpGi" 
         data-getterms-document={type}
         data-getterms-lang="en-us" 
         data-getterms-mode="direct">
      {/* This div will be targeted by the embed script */}
    </div>
  );
};

export default ToSAndPP;
