
import React, { useState, useEffect } from 'react';
import './NavigationBar.css';
import { useNavigate } from 'react-router-dom';
import api from 'utils/api.js';
import Alert from 'components/Alert/Alert';

function NavigationBar() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [alertMessage, setAlertMessage] = useState(null);
  const location = window.location;

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  // const handleStudioClick = () => {
  //   if (isLoggedIn) {
  //     navigate('/studio');
  //   } else {
  //     navigate('/auth');
  //   }
  // };

  const handleAboutClick = () => {
    navigate('/about');
  };

  const handleCareerClick = () => {
    navigate('/career');
  };

  const handleLogout = async () => {
    try {
      const response = await api.handleLogout({});
      if (response.status === 200) {
        localStorage.setItem('isLoggedIn', false); // NOTE: localStorage only stores strings
        localStorage.removeItem('userdata');
        setIsLoggedIn(false);
        navigate('/');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };
  
  return (
    <div className="navigation-bar-container page-container">
      <Alert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <div className="navigation-bar-logo" />
      <div className={`navigation-bar-item ${currentPage === '/' ? 'active' : ''}`} onClick={() => navigate('/')}>Home</div>
      <div className={`navigation-bar-item ${currentPage === '/about' ? 'active' : ''}`} onClick={handleAboutClick}>About</div>
      <div className={`navigation-bar-item ${currentPage === '/career' ? 'active' : ''}`} onClick={handleCareerClick}>Career</div>
      <div style = {{flex: 1}}></div>
      {currentPage === '/auth' ? null : isLoggedIn ?
          <div 
            className="navigation-bar-item right-side" 
            style={{ marginRight: '50px' }}
            onClick={() => handleLogout()}
          > 
            Logout
          </div> :
          <div
            className="navigation-bar-item right-side login-button" 
            style={{ marginRight: '30px' }}
            onClick={ () => navigate('/auth') }
          >
            Login
          </div>
        }
    </div>
  );
}

export default NavigationBar;
