import './IntroPage.css';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { RealmZTextField, RealmZButton } from 'utils/customMui.js';
import { useState } from 'react';
import FooterBar from 'components/FooterBar/FooterBar';
import Alert from 'components/Alert/Alert';
import api from 'utils/api';

function IntroPage() {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [subscribeClicked, setSubscribeClicked] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    // Reset error state whenever the email input changes
    if (error) setError(false);
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };

  const handleSubscribe = () => {
    if (subscribeClicked) return;
    else {
      // set a timeout to prevent multiple clicks
      setSubscribeClicked(true);
      setTimeout(() => {
        setSubscribeClicked(false);
      }, 1000);
      handleSendSubscribe();
    }
  };

  const handleSendSubscribe = async () => {
    if (!validateEmail(email)) {
      setError(true);
    } else {
      setError(false);
      // Send email to backend
      try {
        const response = await api.subscribeEmail({ email: email });
        if (response.status === 200) {
          setEmail('');
          setAlertMessage({
            type: 'success',
            message: 'Subscribed successfully! You will receive an email confirmation shortly.'
          });
        } else {
          setAlertMessage({
            type: 'error',
            message: response.error || 'Failed to subscribe. Please try again later.'
          });
        }
      } catch (error) {
        setAlertMessage({
          type: 'error',
          message: 'Failed to subscribe. Please try again later.'
        });
      }
    }
  };

  return (
    <div className="intro-page-container page-container">
      <Alert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <NavigationBar />
      <div className="intro-page-content">
          <div className="intro-page-content-container slogan-video-container">
            <div className="slogan-pad" />
            <video autoPlay loop muted playsInline className="slogan-video" style={{opacity: 0.8}}>
              <source src="https://realmz-assets.s3.us-east-2.amazonaws.com/slogan.mp4" type="video/mp4" />
            </video>
            <div className="slogan-pad" />
          </div>
          <div className="intro-page-content-container" style={{marginRight: '2rem', minWidth: '300px'}}>
            <div className="follow-us-container">
              <div className="follow-us-title">Sign up to get notified about our product release!</div>
              <div className="follow-us-form">
                <div style={{flex: 1}}>
                  <RealmZTextField
                    size="small"
                    type="email"
                    label="Your email"
                    error={error}
                    helperText={error ? "Invalid email" : ""}
                    value={email}
                    onChange={handleEmailChange}
                    fullWidth
                  />
                </div>
                <RealmZButton
                  className="follow-us-button"
                  onClick={handleSubscribe}
                  variant="contained"
                  size="small"
                  disabled={subscribeClicked}
                >
                  Subscribe
                </RealmZButton>
              </div>
            </div>
          </div>
        </div>
        <FooterBar />
    </div>
  );
}

export default IntroPage;