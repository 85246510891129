import "./FooterBar.css";

import React from "react";
import { useNavigate } from "react-router-dom";

const FooterBar = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-bar page-container">
      <div style={{ flex: 1 }} />
      <div className="footer-bar-content">
        <div className="footer-bar-content-item">
          <div className="footer-bar-content-item-title">Contact:</div>
          <div className="footer-bar-content-item-text">
            <a href="mailto: contact@realmz.ai">contact@realmz.ai</a>
          </div>
        </div>
        <div className="footer-bar-content-item">
          <div className="footer-bar-content-item-title">Privacy:</div>
          <div className="footer-bar-content-item-text">
            <a className="footer-bar-link" href="/privacy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="footer-bar-content-item">
          <div className="footer-bar-content-item-title">Terms:</div>
          <div className="footer-bar-content-item-text">
            <a className="footer-bar-link" href="/terms/" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </div>
        </div>
        <div className="footer-bar-content-item">
          <div className="footer-bar-content-item-title">Career:</div>
          <div className="footer-bar-content-item-text">
            <div className="footer-bar-link" onClick={() => navigate("/career")}>
              Join Us
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bar-content">
        <div className="footer-bar-content-item">
          <div className="footer-bar-content-item-text">
          <div className="footer-bar-content-item-container">
            <div className="footer-bar-inline-icon linkedin-icon" />
              <a href="https://www.linkedin.com/company/realmz/" target="_blank" rel="noreferrer">
                LinkedIn
              </a>
            </div>
            <div className="footer-bar-content-item-container">
              <div className="footer-bar-inline-icon github-icon" />
              <a href="https://www.github.com/realmz-ai" target="_blank" rel="noreferrer">
                GitHub
              </a>
            </div>
            <div className="footer-bar-content-item-container">
              <div className="footer-bar-inline-icon instagram-icon" />
              <a href="https://www.instagram.com/realmz.ai/" target="_blank" rel="noreferrer">
                Instagram
              </a>
            </div>
            <div className="footer-bar-content-item-container">
              <div className="footer-bar-inline-icon twitter-icon" />
              <a href="https://www.twitter.com/realmz_ai" target="_blank" rel="noreferrer">
                Twitter
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bar-content-item">
          <div className="footer-bar-content-item-title">
            © 2024 Realmz, Inc. All rights reserved.
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
}

export default FooterBar;