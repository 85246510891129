import "./ContactPanel.css";

const ContactPanel = () => {
  return (
    <div className="contact-panel-container">
    </div>
  );
}

export default ContactPanel;
