import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const RealmZTextField = styled(TextField)(({ theme, error }) => ({
  verticalAlign: 'middle',
  height: 42,
  // Label styles
  '& label': {
    color: '#fff', // Default label color
  },
  '& label.Mui-focused': {
    color: '#fff', // Label color when focused
  },
  // Underline styles (for non-outlined variants, if applicable)
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white', // Underline color after focus
  },
  // Outlined Input styles
  '& .MuiOutlinedInput-root': {
    // Default state
    '& fieldset': {
      borderColor: error ? '#ff7777' : '#fff', // Default border color, changes to red if error
    },
    // Hover state
    '&:hover fieldset': {
      borderColor: 'white', // Border color on hover
    },
    // Focused state
    '&.Mui-focused fieldset': {
      borderColor: error ? '#ff7777' : '#add5ff', // Keep red if error, otherwise use focus color
    },
    // Input and Textarea styles
    '& input, & textarea': {
      color: '#fff', // Text color
      fontSize: '1rem',
    },
    '&.Mui-focused input, &.Mui-focused textarea': {
      color: '#add5ff', // Text color when focused
    },
    // Autofill styles
    '& input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #000 inset',
      WebkitTextFillColor: '#fff',
      caretColor: '#fff',
    },
    '& input:-webkit-autofill:focus': {
      WebkitBoxShadow: '0 0 0 100px #000 inset',
      WebkitTextFillColor: '#add5ff',
    },
  },
  // Placeholder styles
  '& .MuiInputBase-input::placeholder': {
    color: 'rgba(255, 255, 255, 0.6)', // Placeholder text color
    opacity: 1, // Ensure visibility
  },
  // Label color adjustment for outlined variant on focus
  '& .MuiInputLabel-outlined.Mui-focused': {
    color: '#add5ff', // Label color when input is focused
  },

  // Styles applied when there's an error
  ...(error && {
    '& label, & .MuiInputLabel-outlined': {
      color: '#ff7777', // Label color when there's an error
    },
    // label when focused
    '& label.Mui-focused, & .MuiInputLabel-outlined.Mui-focused': {
      color: '#add5ff', // Label color when there's an error
    },
    '& input, & textarea': {
      color: '#ff7777', // Text color
      fontSize: '1rem',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ff7777', // Border color when there's an error
        color: '#fff'
      },
      // when focused
      '&.Mui-focused fieldset': {
        borderColor: '#add5ff', // Border color when there's an error
      },
      // text area color when focused
      '&.Mui-focused input, &.Mui-focused textarea': {
        color: '#add5ff', // Text color when focused
      },
      // autofill styles
      '& input:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 100px #000 inset',
        WebkitTextFillColor: '#ff7777',
        caretColor: '#ff7777',
      },
    },
  }),
}));



const RealmZButton = styled(Button)(({ theme, type }) => ({
  verticalAlign: 'middle',
  background: 'linear-gradient(45deg, #4dabf5 30%, #4fc3f7 90%)',
  border: 0,
  color: 'white',
  height: 45,
  top: '-1px',
  padding: '0 20px',
  boxShadow: '0 0px 10px 6px rgba(100, 100, 100, 0.4)',
  ...(type === 'secondary' && {
    height: 40,
    background: '#eeeeee',
    color: '#555',
    // on hover
    '&:hover': {
      background: '#cdcdcd',
    },
  }),
}));

export { RealmZTextField, RealmZButton };