import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Alert.css';

function Alert({ alertMessage, setAlertMessage }) {
    const [parsedMessage, setParsedMessage] = useState({ message: '', type: '' });

    useEffect(() => {
        if (alertMessage) {
            // if it containes a message
            let message = '';
            let type = '';
            if (alertMessage.message) {
                message = alertMessage.message;
            }
            if (alertMessage.type) {
                type = alertMessage.type;
            }
            setParsedMessage({ message, type });

            // set the timeout to hide the alert
            const timeout = setTimeout(() => {
                setAlertMessage(null);
                setParsedMessage({ message: '', type: '' });
            }, 2200);

            return () => clearTimeout(timeout);
        }
    }, [alertMessage, setAlertMessage]);

    return ReactDOM.createPortal(
        <div className={`alert-container ${parsedMessage.type} ${parsedMessage.message ? 'show' : 'hide'}`}>
            { parsedMessage.message }
        </div>,
        document.getElementById('alert-and-toast-portal')
    );
}

export default Alert;
