import './ThirdPartyAuth.css';
import React from 'react';
import { useGoogleLogin } from '@react-oauth/google'

function ThirdPartyAuth() {
    // use useGoogleLogin hook to get the authentication function
    const handleGoogleLogin = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'redirect',
        redirect_uri: 'http://localhost:3000/auth/redirect',
        scope: 'openid email profile',
    });

    return (
        <div className="third-party-auth-container">
        {/* <div className="third-party-auth-title">Or login with:</div> */}
        <div
            className="third-party-auth-item google"
            onClick={handleGoogleLogin}
        />
        <div className="third-party-auth-item apple disabled" />
        <div className="third-party-auth-item facebook disabled" />
        <div className="third-party-auth-item x disabled" />
    </div>
    );
}

export default ThirdPartyAuth;
