import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from 'components/Alert/Alert';
import { RealmZTextField, RealmZButton } from 'utils/customMui';
import { Box } from '@mui/material';
import api from 'utils/api';
import { validateEmail } from 'utils/helperFunctions';

function EmailRegister() {
  const [registerPhase, setRegisterPhase] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      const credentials = {
        type: "email",
        email: email,
        username: username,
        password: password,
      };
      const res = await api.handleRegister(credentials);
      if (res.status === 200) {
        localStorage.setItem('userdata', JSON.stringify(res.data));
        localStorage.setItem('isLoggedIn', true);
        navigate('/');
      }
    } catch (error) {
      console.error('Register error:', error);
      setAlertMessage({ type: 'error', message: error.response.data.message });
    }
  };

  // const checkEmailExists = async (_, value) => {
  //   try {
  //     const response = await api.checkEmailExists({ email: value });
  //     const statusCode = response.status;
  //     if (statusCode === 200) {
  //       return Promise.reject('Email already exists');
  //     } else if (statusCode === 204) {
  //       return Promise.resolve();
  //     } else {
  //       return Promise.reject('Email check error');
  //     }
  //   } catch (error) {
  //     console.error('Email check error:', error);
  //     return Promise.reject('Email check error');
  //   }
  // };

  const handleEmailValidation = () => {
    validateEmail(email).then(() => {
      setEmailError(false);
    }).catch((error) => {
      setEmailError(true);
      setAlertMessage({ type: 'error', message: error });
    });
  };

  const handleUsernameValidation = () => {
    if (!username) {
      setUsernameError(true);
      setAlertMessage({ type: 'error', message: 'Username is required' });
    } else {
      setUsernameError(false);
    }
  };

  const validateAndNext = () => {
    // Example validation logic before proceeding to the next phase or submitting
    if (registerPhase === 0) {
      checkUsernameAndEmail().then(() => {
        setRegisterPhase(1); // Proceed to next phase
      }).catch((error) => {
        setAlertMessage({ type: 'error', message: error });
      });
    } else {
      // Validate password and submit
      checkPassword().then(() => {
        confirmPasswordMatch().then(() => {
          handleRegister();
        }).catch((error) => {
          setAlertMessage({ type: 'error', message: error });
          setConfirmPasswordError(true);
        });
      }).catch((error) => {
        setAlertMessage({ type: 'error', message: error });
      });
    }
  };

  const checkUsernameAndEmail = () => {
    if (!username) {
      return Promise.reject('Username is required');
    } else if (!email) {
      return Promise.reject('Email is required');
    } else {
      return validateEmail(email);
    }
  }

  const confirmPasswordMatch = () => {
    if (confirmPassword !== password) {
      return Promise.reject('Passwords do not match');
    }
    return Promise.resolve();
  };

  const onPasswordBlur = () => {
    checkPassword().then(() => {
      setPasswordError(false);
      if (confirmPassword) {
        confirmPasswordMatch().then(() => {
          setConfirmPasswordError(false);
        });
      }
    }).catch((error) => {
      setAlertMessage({ type: 'error', message: error });
      setPasswordError(true);
    });
  };

  const onConfirmPasswordBlur = () => {
    confirmPasswordMatch().then(() => {
      setConfirmPasswordError(false);
    }).catch((error) => {
      setAlertMessage({ type: 'error', message: error });
      setConfirmPasswordError(true);
    });
  };

  const checkPassword = () => {
    if (!password) {
      return Promise.reject('Password is required');
    } else if (password && password.length < 8) {
      return Promise.reject('Password must be at least 8 characters');
    } else if (password && !/[0-9]/.test(password)) {
      return Promise.reject('Password must contain at least one number');
    } else if (password && !/[a-z]/.test(password)) {
      return Promise.reject('Password must contain at least one lowercase letter');
    } else if (password && !/[A-Z]/.test(password)) {
      return Promise.reject('Password must contain at least one uppercase letter');
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Alert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      {registerPhase === 0 ? (
        <>
          <RealmZTextField 
            className="auth-input-box"
            label="Username" 
            value={username} 
            onChange={e => setUsername(e.target.value)}
            error={usernameError}
            onBlur={handleUsernameValidation}
            size="small"
            required
          />
          <RealmZTextField 
            className="auth-input-box" 
            label="Email" 
            value={email} 
            onChange={e => setEmail(e.target.value)} 
            size="small"
            error={emailError}
            onBlur={handleEmailValidation}
            required
          />
        </>
      ) : (
        <>
          <RealmZTextField 
            className="auth-input-box" 
            label="Password" 
            type="password" 
            value={password} 
            onChange={e => setPassword(e.target.value)}
            onBlur={onPasswordBlur}
            size="small" 
            error={passwordError}
            required
          />
          <RealmZTextField
            className="auth-input-box"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            onBlur={onConfirmPasswordBlur}
            size="small"
            error={confirmPasswordError}
            required
          />
        </>
      )}
      <RealmZButton onClick={validateAndNext} className="auth-button" variant="contained" size="small" type="secondary">
        {registerPhase === 1 ? 'Register' : 'Next'}
      </RealmZButton>
    </Box>
  );
}

export default EmailRegister;
