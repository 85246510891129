import React from 'react';
import './AboutPage.css';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import ContactPanel from './ContactPanel/ContactPanel';
import FooterBar from 'components/FooterBar/FooterBar';

function AboutPage() {
  return (
    <div className="about-page-container page-container">
      <NavigationBar />
      <div className="about-page-content-container">
        <div className="about-page-content" style={{ flex: 2 }}>
          <div className="about-page-content-title">
            About Us
          </div>
          <div className="about-page-content-description">
            RealmZ is an AI company based in Philadelphia, PA. Our founders are alumni of the University of Pennsylvania and 
            GRASP Lab. We aim to reinvent the entertainment media by creating immersive and truely interactive contents.
          </div>
          <div className="about-page-content-description">
            Our RealmZ engine provide developers with tools that create interactive and immersive texts, videos, and games.
            We also use generative AI and state-of-the-art rendering techniques to build hyper-realistic, real-time, and cost effencient rendering engines.
          </div>
        </div>
        <div className="about-page-content">
          <ContactPanel />
        </div>
      </div>
      <FooterBar />
    </div>
  );
}

export default AboutPage;
