import "./JSONDisplayer.css";

import React from 'react';

const JSONDisplayer = ({ content }) => {
  const handleButtonClick = (onClick) => {
    if (onClick.action === 'open-url') {
      window.open(onClick.url, '_blank');
    }
  }
  return (
    <div className="json-displayer">
      <div className="json-displayer-title">
        {content.title}
      </div>
      <div className="json-displayer-content">
        {content.body.map((item, index) => {
          return item.type === 'list' ? (
            item.content.map((listItem, lindex) => (
              <div className="json-displayer-list-item" key={lindex}>
                <div className="json-displayer-list-dot">•</div>
                <div className="json-displayer-list-item-text">
                  {listItem}
                </div>
              </div>
            ))
          ) : item.type === 'button' ? (
            <div className="json-displayer-button" key={index} onClick={() => handleButtonClick(item.onClick)}>
              {item.content}
            </div>
          ) : (
            <div className={`json-displayer-text ${item.type}`} key={index}>
              {item.content}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default JSONDisplayer;
