import { RealmZButton } from "utils/customMui";
import "./CookiesAgreement.css";

import React, { useEffect } from "react";

const CookiesAgreement = () => {
  useEffect(() => {
    const cookiesAgreement = localStorage.getItem("cookiesAgreement");
    if (cookiesAgreement !== "true") {
      document.getElementById("cookies-agreement").style.display = "block";
    }
  }, []);

  const agreeToCookies = () => {
    localStorage.setItem("cookiesAgreement", "true");
    document.getElementById("cookies-agreement").style.display = "none";
  };

  return (
    <div id="cookies-agreement" className="cookies-agreement">
      <div className="cookies-agreement-content">
        <div className="cookies-agreement-content-text">
          We use cookies to improve your experience on our site. By clicking "Agree", you agree to our use of cookies. See our <a className="footer-bar-link" href="/privacy/">Privacy Policy</a> to learn more.
        </div>
        <RealmZButton onClick={agreeToCookies} variant="contained" size="small" type="secondary" fullWidth>
          Agree
        </RealmZButton>
      </div>
    </div>
  );
}

export default CookiesAgreement;
