// src/pages/NotFoundPage/NotFoundPage.js

import React from 'react';
import './NotFoundPage.css';

function NotFoundPage() {
    return (
        <div className="not-found-page-container page-container">
            <div className="not-found-page-content">
                <div className="not-found-show-logo"></div>
                <div className="not-found-text" >The page you are looking for does not exist.</div>
                <div 
                    className="not-found-text" 
                    onClick={() => window.location.href = '/'}
                    style={{ cursor: 'default', textDecoration: 'underline', color: '#1890ff' }}
                >
                    Back to Home
                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;