import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from 'components/Alert/Alert';
import { Box } from '@mui/material';
import { RealmZTextField, RealmZButton } from 'utils/customMui';
import api from 'utils/api';
import { validateEmail } from 'utils/helperFunctions';

function EmailLogin() {
  const [alertMessage, setAlertMessage] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    setButtonDisabled(true);
    try {
      const credentials = {
        email: email,
        password: password,
        type: 'email',
      };
      const res = await api.handleLogin(credentials);
      localStorage.setItem('userdata', JSON.stringify(res.data));
      localStorage.setItem('isLoggedIn', true);
      navigate('/');
    } catch (error) {
      setAlertMessage({ type: 'error', message: error.response.data.message });
    } finally {
      setButtonDisabled(false);
    }
  };

  const handleEmailValidation = () => {
    validateEmail(email).then(() => {
      setEmailError(false);      
    }).catch((error) => {
      setEmailError(true);
      setAlertMessage({ type: 'error', message: error });
    });
  }

  const onSubmit = () => {
    validateEmail(email).then(() => {
      if (password.length > 0) {
        handleLogin();
      } else {
        setAlertMessage({ type: 'error', message: 'Password cannot be empty' });
      }
    }).catch((error) => {
      setAlertMessage({ type: 'error', message: error });
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Alert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
      <RealmZTextField
        className="auth-input-box" 
        label="Email"
        variant="outlined"
        value={email}
        error={emailError}
        onBlur={handleEmailValidation}
        onChange={(e) => setEmail(e.target.value)}
        size="small"
        fullWidth
        margin="normal"
        required
      />
      <RealmZTextField
        className="auth-input-box" 
        label="Password"
        type="password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        size="small"
        fullWidth
        margin="normal"
        required
      />
      <RealmZButton
        className="auth-button"
        variant="contained"
        onClick={onSubmit}
        disabled={buttonDisabled}
        sx={{ mt: 2 }}
        fullWidth
        type="secondary"
      >
        Login
      </RealmZButton>
    </Box>
  );
}

export default EmailLogin;
