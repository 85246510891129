import React from 'react';
import './CareerPage.css';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import FooterBar from 'components/FooterBar/FooterBar';
import CareerDrawer from './CareerDrawer/CareerDrawer';

import mle_cv_intern from './positions/mle_cv_intern.json';
import sde_intern from './positions/sde_intern.json';

const positionJSONArray = [
  mle_cv_intern,
  sde_intern
];

function CareerPage() {
  return (
    <div className="career-page-container page-container">
      <NavigationBar />
      <div className="career-page-content-container">
        <div className="career-page-content-title">
          Career
        </div>
        <div className="career-page-content">
          {positionJSONArray.map((position, index) => (
            <CareerDrawer
              key={index}
              content={position}
            />
          ))}
        </div>
      </div>
      <FooterBar />
    </div>
  );
}

export default CareerPage;
