import axios from 'axios';

class api {
    constructor() {
        const baseUrl = process.env.REACT_APP_ENV === 'production'
        ? process.env.REACT_APP_PROD_URL
        : process.env.REACT_APP_DEV_URL;

        this.api = axios.create({
            baseURL: baseUrl,
            timeout: 10000,
            withCredentials: true,
        });

        this.initializeInterceptors();
    }

    initializeInterceptors() {
        // Request Interceptor
    
        // Response Interceptor
        this.api.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response && error.response.status === 401) {
                // Check if the error is due to an expired session
                if (error.response.data.reason === 'session_expired') {
                    // Redirect to login page or handle session expiry
                    console.error("Session expired - Redirecting to login");
                } else {
                    // Handle other types of unauthorized access
                    console.error("Unauthorized access");
                }
            }
            return Promise.reject(error);
        });
    }

    async handleLogin(data) {
        return await this.api.post('/auth/login', data);
    }
    
    async handleRegister(data) {
        return await this.api.post('/auth/register', data);
    }

    async handleLogout(data) {
        return await this.api.post('/auth/logout', data);
    }

    async checkEmailExists(data) {
        return await this.api.post('/auth/email-exists', data);
    }

    async getUserInfo(uuid) {
        return await this.api.get('/get/' + uuid);
    }

    async saveProject(data) {
        return await this.api.post('/project/save', data);
    }

    async getProjectList() {
        return await this.api.get('/project/list-all');
    }

    async getProject(projectId) {
        return await this.api.get('/project/get/' + projectId);
    }

    async deleteProject(projectId) {
        return await this.api.delete('/project/delete/' + projectId);
    }

    async saveNodePromptDetails(data) {
        return await this.api.post('/project/save/node-prompt-details', data);
    }

    async getNodePromptDetails(projectUuid, nodeUuid) {
        return await this.api.get('/project/get/node-prompt-details/' + projectUuid + '/' + nodeUuid);
    }

    async subscribeEmail(data) {
        return await this.api.post('/email/subscribe', data);
    }
}

const apiInstance = new api();

export default apiInstance;
