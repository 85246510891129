import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');

    if (isLoggedIn === 'true') {
        // User is authenticated, redirect from login page to compose page
        return <Navigate to="/" replace />;
    }

    // User is not authenticated, render the requested route
    return children;
};

const UnAuthGuard = ({ children }) => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');

    if (isLoggedIn !== 'true') {
        // User is not authenticated, redirect from protected routes to auth page
        return <Navigate to="/auth" replace />;
    }

    // User is authenticated, render the requested route
    return children;
};

export { AuthGuard, UnAuthGuard };
