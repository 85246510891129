import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from 'utils/api.js';
import './AuthRedirectPage.css';

const AuthRedirectPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const redirectTriggeredRef = useRef(false);

    useEffect(() => {
        if (redirectTriggeredRef.current) return;
        redirectTriggeredRef.current = true;
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
    
        if (code) {
            api.handleLogin({ type: "google", authCode: code })
                .then(res => {
                    localStorage.setItem('userdata', JSON.stringify(res.data));
                    localStorage.setItem('isLoggedIn', true);
                    navigate('/');
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [location, navigate]);
    
    return (
        <div className="login-redirect-page-container page-container">
            <div className="login-logo" onClick={() => navigate('/')} ></div>
            <div className="login-redirection-text">
                Please wait while we redirect you ...
            </div>
            <div className="login-redirection-text">
                If you are not redirected automatically, click&nbsp;
                <span 
                    style={{ color: '#1890ff', cursor: 'default', textDecoration: 'underline' }}
                    onClick={() => navigate('/')}
                >
                    here
                </span>
            </div>
        </div>
    );
};

export default AuthRedirectPage;
