import "./CareerDrawer.css";

import React, { useEffect, useState } from 'react';
import JSONDisplayer from 'components/JSONDisplayer/JSONDisplayer';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CareerDrawer = ({ content }) => {
  const [open, setOpen] = useState(false);
  const [collapsedContent, setCollapsedContent] = useState({title: "", body: []});

  useEffect(() => {
    // filter out the collapsed content
    if (content.body.length > 0) {
      const collapseIndices = content.collapseIndices || [];
      // make a deep copy of the content
      const filteredContent = {};
      filteredContent.title = content.title;
      filteredContent.body = content.body.filter((item, index) => {
        return collapseIndices.includes(index);
      });
      setCollapsedContent(filteredContent);
    }
  }, [content]);

  return (
    <div className="career-drawer">
      <div className={`career-drawer-content ${open ? 'open' : 'closed'}`}>
        {open ? (
          <JSONDisplayer content={content} />
        ) : (
          <JSONDisplayer content={collapsedContent} />
        )}
      </div>
      <div
        className="career-drawer-button"
        onClick={() => setOpen(!open)}
      >
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
    </div>
  );
}

export default CareerDrawer;
