// src/pages/AuthenticationPage/AuthenticationPage.js

import React, { useState } from 'react';
import './AuthenticationPage.css';
import EmailLogin from './EmailLogin/EmailLogin.js';
import EmailRegister from './EmailRegister/EmailRegister.js';
import ThirdPartyAuth from './ThirdPartyAuth/ThirdPartyAuth';
import NavigationBar from 'components/NavigationBar/NavigationBar';

function AuthenticationPage() {
    const [loginOrRegister, setLoginOrRegister] = useState('login'); // ['login', 'register']

    return (
        <div className="authentication-page page-container">
            <NavigationBar />
            <div className="authentication-container">
                <div className="logo-box" ></div>

                { loginOrRegister === 'login' ? <EmailLogin /> : <EmailRegister /> }

                <div className="register-link-container">
                    <span>{ loginOrRegister === "login" ? "Don't have an account?" : "Already have an account?"}&nbsp;</span>
                    <span className="register-link">
                        { loginOrRegister === "login" ? 
                        (<span onClick={() => setLoginOrRegister('register')}>Register</span>) :
                        (<span onClick={() => setLoginOrRegister('login')}>Login</span>) }
                    </span>
                </div>

                <div className="divider"></div>

                <ThirdPartyAuth />

            </div>
        </div>
    );
}

export default AuthenticationPage;
